@tailwind base;
@tailwind components;
@tailwind utilities;

html {
	scroll-behavior: smooth;
}

.high-contrast *:not(.a11y) {
	background-color: black !important;
	color: yellow !important;
}

.high-contrast .burger-button-line {
	background-color: yellow !important;
}

.high-contrast a:not(.a11y) {
	color: cyan !important;
}

.increased-font-size * {
	font-size: 1.5rem !important;
	line-height: 2.25rem !important;
}
